import React from 'react';
import PropTypes from 'prop-types';
import Layout2Columns from './layout-2col';

function ProductInfo({ imgSrc, imgAlt, textTitle, textSubtitle, textDscItems, textButton, handleButtonClick, orderTextLeft }) {

    const textPart = <div className="flex flex-col pl-4 md:pl-16 text-left justify-center">
        <h2 className="font-bold uppercase text-3xl text-gray-800">{textTitle}</h2>
        <p className="text-sm text-gray-700 ml-6 mb-8">{textSubtitle}</p>
        <ul className="list-disc text-sm text-gray-900 mx-8">
            {textDscItems.map((t,i) => {
                return <li key={i} className="">{t}</li>
            })}
        </ul>
        {
            (textButton) ?
                <button className="btn btn-magenta self-center" onClick={handleButtonClick}>{textButton}</button>
                : null
        }
    </div>;
    const imagePart = <div className="p-4">
        <img className="shadow-md block object-contain " src={imgSrc} alt={imgAlt} />
    </div>;

    return (
        (orderTextLeft) ?
            <Layout2Columns>{textPart}{imagePart}</Layout2Columns>
            :
            <Layout2Columns>{imagePart}{textPart}</Layout2Columns>
    );
}


ProductInfo.defaultProps = {
    imgSrc: "",
    imgAlt: "",
    textTitle: "",
    textSubtitle: "",
    textButton: "",
    textDscItems: ["A", "B", "C",],
    orderTextLeft: true,
    handleButtonClick: ()=>{},
};

ProductInfo.propTypes = {
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    textButton: PropTypes.string,
    handleButtonClick: PropTypes.func,
    textTitle: PropTypes.string,
    textSubtitle: PropTypes.string,
    textDscItems: PropTypes.array,
    orderTextLeft: PropTypes.boolean
};

export default ProductInfo;
