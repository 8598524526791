import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Title({ text, className, h2, h3 }) {
    if (h3) {
        return (
            <h3 className={classNames("font-narrow uppercase text-1xl text-gray-700", className)}>
                {text}
            </h3>
        );
    }
    if (h2) {
        return (
            <h2 className={classNames("font-narrow uppercase text-3xl text-gray-800", className)}>
                {text}
            </h2>
        );
    }

    return (
        <h1 className={classNames("text-5xl text-gray-900 font-narow text-center", className)}>
            {text}
        </h1>
    );
}

Title.defaultProps = {
    text: "",
    h2: false,
    h3: false,
    className: "",
};

Title.propTypes = {
    h2: PropTypes.boolean,
    h3: PropTypes.boolean,
    text: PropTypes.string,
    className: PropTypes.string,
};

export default Title;
