import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-text-to-clipboard';
import classNames from 'classnames';
import { FaRegCopy } from 'react-icons/fa';



const TextToClip = ({ text, className}) => {
    const handleClick = () => {
        copy(text);
    };

    return (
        <div className="flex self-center">
            <p className={classNames("px-2", className) } onClick={handleClick}>{text}</p> 
            <FaRegCopy className={className} />
        </div>
    );
}

TextToClip.defaultProps = {
    text: "",
    className: "",
};

TextToClip.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
};

export default TextToClip;