import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CtaHero from "../components/cta-hero";
import CtaImageButton from "../components/cta-image-button";
import ReactPlayer from 'react-player'

import LayoutPage from "../components/layout-page";
import SEO from "../components/seo";
import TextToClip from "../components/text-to-clip";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";
import dogIllustration from "../images/dog-illustration.svg";
import logo from '../images/ataks-logo.png';
import hero from '../images/flairosol-hero.jpg';
import flairosol from '../images/flairosol.jpg';
import opad from '../images/opad.jpg';
import opad2 from '../images/opad2.jpg';
import opus from '../images/opus.jpg';

import { defaultKeywords } from "./seo.json"
import Title from "../components/title";
import SectionMain from "../components/section-main";
import ProductInfo from "../components/product-info";
import Separator from "../components/separator";
import Layout2Columns from "../components/layout-2col";
import CompanyInfo from "../components/company-info";
import { isMobileWidth, useWindowSize } from "../utils/windowSize";
import Layout3Columns from "../components/layout-3col";
import { camelCase } from 'lodash';

function IndexPage() {

  const windowSize = useWindowSize();

  const mobileLayout = isMobileWidth(windowSize);
  console.log("mobileLayout");
  console.log(mobileLayout);
  return (
    <LayoutPage>
      <SEO
        keywords={[...defaultKeywords, `about`]}
        title="Ataks s.r.o."
      />
      <div className="flex w-screen mb-4">
        <CtaHero
          textTitle={"Take a step to the next generation of triggers"}
          textButton={"Discover Flairosol"}
          imgSrc={hero}
          handleButtonClick={() => { window.location = "#flairosol" }}
          className="bg-left"
        />
      </div>
      <Separator />
      <SectionMain id="about" className="">
        <Separator />
        <Title text={"About"} />
        <Separator />
        <Layout2Columns>
          {[
            { "title": "Heritage and trust", "text": "Our company was founded in 1992 and we are the supplier of plastic triggers and other designed packaging for Czech and Slovak Republic." },
            { "title": "Top quality products", "text": "Our triggers are not standard triggers, but the top quality triggers with the pre-compresion technology that will help your brand to stand out." },
            { "title": "Wide market adaptation", "text": "We are proud to deliver our product to companies working in the chemical, automotive, cleaning and pharmaceutical industry as well as veterinary industry and also personal, garden and home care." },
            { "title": "Customer at first place", "text": "We do our best to insure best delivery times and reliable planning. Products are stocked in even years advance." },
          ].map((item) => {
            return <div key={camelCase(item.title)} className="m-6 md:mx-8 md:my-6 ">
              <Title text={item.title} h3 className="" />
              <p className="">{item.text}</p>
            </div>
          })}
        </Layout2Columns>
        <Separator className="mt-32" />
        {/* <Title text="Only the best for our customers" h2 className="text-center" /> */}
          <Title text="You’re in a good company" h2 className="text-center" />
        <Layout3Columns>
          {[
            { "number": "5+ mil", "title": "Triggers", "text": "delivered per year" },
            { "number": "29", "title": "Years", "text": "at the market" },
            { "number": "50+", "title": "Customers", "text": "served by our products" },
          ].map((item) => {
            return <div key={camelCase(item.title)} className="m-6 md:mx-8 md:my-6 text-center">
              <Title text={item.number} h2 className="" />
              <Title text={item.title} h3 className="text-xl" />
              <p className="">{item.text}</p>
            </div>
          })}
        </Layout3Columns>
        <Separator className="mt-32" />
        {/* <Title text="You’re in a good company" h2 className="text-center" /> */}
        {/* <Title text="We are providing top quality triggers since 1992 for many customers from Czech and Slovak Republic." h3 className="text-center text-narrow text" /> */}
        
      </SectionMain>
        {/* <div className="flex justify-center items-center">
          {[
            { "url": "https://www.bochemie.cz/sites/default/files/menu/logo.png", "alt": "Bochemie" },
            { "url": "https://www.bochemie.cz/sites/default/files/menu/logo.png", "alt": "Bochemie" },
            { "url": "https://www.bochemie.cz/sites/default/files/menu/logo.png", "alt": "Bochemie" },
            { "url": "https://www.bochemie.cz/sites/default/files/menu/logo.png", "alt": "Bochemie" },
            { "url": "https://www.bochemie.cz/sites/default/files/menu/logo.png", "alt": "Bochemie" },
          ].map((item) => {
            return <div key={camelCase(item.alt)} className="flex justify-center items-center m-3 md:mx-6 h-32 w-32">
              <img className=" object-contain " src={item.url} alt={item.alt} /> 
            </div>
          })
          }
        </div> */}
        <Separator />
      <hr />
      <SectionMain id="products" className="">
        <Separator />
        <Title text={"Products"} />
        <Separator />
      </SectionMain>
      <SectionMain id="flairosol" className="">
        <ProductInfo
          textTitle={"Flairosol"}
          textSubtitle={" Next GEN. trigger-based dispensing technology meets demand for finest mist."}
          textDscItems={[
            "Delivering a remarkable spray quality",
            "Very fine mist combined with continuous spray functionality", 
            "Superior ergonomics and 360° dispensing",
            "Full plastic, no metal parts",
            "Environmentally friendly",
            "Easily recycleable",
            "Non-pressurized propellant-free spray package with an aerosol-quality spray.",
            "Bridging the gap between triggers & aerosols",
            "Spray evenly even upside down",
            "Rechargable bottle",
          ]}
          imgSrc={flairosol}
          imgAlt={"Flairosol"}
          textButton={""}
          handleButtonClick={() => { }}
          orderTextLeft={!mobileLayout}
        />
      </SectionMain>
          <Separator />
          <section className="flex bg-gray-900 justify-center items-center">
           <ReactPlayer url="https://youtu.be/2kclnG1_Fvo" className="self-center"/>
          </section>
          <Separator />
      <SectionMain id="opus" className="">
        <ProductInfo
          textTitle={"OpUs"}
          textSubtitle={"Advanced Trigger sprayer with all-plastic polyolefin construction, pre-compression functionality and stylish looks and feel."}
          textDscItems={[
            "Customizable colors and transparencies",
            "Minimum fallout – no drips",
            "Constant accurate output",
            "No leaking when nozzle is open",
            "Always closed",
            "All-plastic polyolefin construction, no metal parts",
            "Easily recycleable",
            "Certified child-resistant trigger sprayers in Europe and the United States",
            "Continuous thread/screw-on and snap-on neck finishes available",
            "Variety of spray and foam patterns available",
          ]}
          imgSrc={opus}
          imgAlt={"OpUs"}
          textButton={""}
          handleButtonClick={() => { }}
          orderTextLeft={ false }
        />
      </SectionMain>
      <SectionMain id="opad2" className="">
        <ProductInfo
          textTitle={"OpAd2"}
          textSubtitle={"Successor of our succesful OpAd trigger sprayer. Provides a perfect foam and spray pattern and with 2 cc output."}
          textDscItems={[
            "OnePak pre-compression engine",
            "No dripping",
            "No leaking",
            "Powerful output",
            "Light-weight",
            "Robust look and feel",
            "Modern and appealing design",
            "Maximum evacuation of the liquid because of the curved dip tube",
            "All-plastic polyolefin construction, no metal parts",
            "Easily recycleable",
          ]}
          imgSrc={opad2}
          imgAlt={"OpAd2"}
          textButton={""}
          handleButtonClick={() => { }}
          orderTextLeft={ !mobileLayout }
        />
      </SectionMain>
      <SectionMain id="opad" className="">
        <ProductInfo
          textTitle={"OpAd®"}
          textSubtitle={"Successful Pre-compression trigger sprayer with branding a top. OpAd has been on the market since 2002 and has been a success ever since."}
          textDscItems={[
            "High sprayer convenience",
            "Low trigger push-back",
            "No liquid residue",
            "High spray performance",
            "No dripping",
            "No fall-out",
            "Consistant spray/foam patterns",
            "Full plastic, no metal parts",
            "Easily recycleable",
          ]}
          imgSrc={opad}
          imgAlt={""}
          textButton={""}
          handleButtonClick={() => { }}
          orderTextLeft={false}
        />
      </SectionMain>
      <Separator />

      <hr />
      <SectionMain id="contact" className="h-auto">
        <Separator />
        <Title text={"Contact"}></Title>
        <Separator />
        <CtaImageButton
          className=" "
          imgSrc={logo}
          imgAlt="Ataks s.r.o."
          textTitle="Contact"
          textButton="Send us an email"
          handleButtonClick={() => { window.location.href = "mailto:info@ataks.cz" }}
        >
          <p className="text-gray-800 self-center">or use</p>
          <TextToClip text='info@ataks.cz' className="text-gray-800 self-center" />
        </CtaImageButton>
        <Separator className="mt-32" />
        <hr />

        <Separator />
        <h2 className="font-narrow uppercase text-3xl text-gray-800 text-center">{"Company Information"}</h2>
        <Separator />
        <CompanyInfo classNameTexts=" rounded bg-white shadow-md p-8" />

      </SectionMain>
      <Separator />
    </LayoutPage>
  );
}

export default IndexPage;
