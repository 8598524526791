import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CtaHero = ({ className, imgSrc, textTitle, handleButtonClick, textButton }) => {
    return (
        <div className={classNames("flex flex-col w-full bg-cover bg-no-repeat bg-center justify-center", className)}  style={{ "background-image": `url(${imgSrc})` }}>
            <h1 className="text-5xl text-magenta font-narow text-center mt-32">{textTitle}</h1>
            <button className="btn btn-magenta p-3 mt-8 mb-32 self-center" onClick={handleButtonClick}>{textButton}</button>
        </div>
    );
}

CtaHero.defaultProps = {
    imgSrc: "",
    textButton: "",
    textTitle: "",
    className: "",
    handleButtonClick: () => {},
};

CtaHero.propTypes = {
    imgSrc: PropTypes.string,
    className: PropTypes.string,
    textTitle: PropTypes.string.isRequired,
    textButton: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func,
};

export default CtaHero;