import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Layout3Columns({ children, className }) {
    return (
        <div className={classNames("grid grid-cols-1 md:grid-cols-3", className)}>{children}</div>
    );
}

Layout3Columns.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Layout3Columns;
