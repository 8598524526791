import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CtaImageButton = ({ imgSrc, imgAlt, textButton, handleButtonClick, children, className}) => {
    return (
        <div className={classNames("flex flex-col justify-center self-center", className)}>
            <img className="block object-contain mt-8 h-16" src={imgSrc} alt={imgAlt} /> 
            <button className="btn btn-magenta uppercase p-3 mt-8 mb-8 self-center" onClick={handleButtonClick}>{textButton}</button>
            {children}
        </div>
    );
}

CtaImageButton.defaultProps = {
    imgSrc: "",
    imgAlt: "",
    textButton: "",
    handleButtonClick: () => {},
    classNames: "",
};

CtaImageButton.propTypes = {
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    textButton: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default CtaImageButton;